const VCIcon = ({ fill = '#1C1B1F', ...props }) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="mask0_1_7custom1" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
      <rect x="0.5" y="0.5" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1_7custom1)">
      <path
        d="M4.5 21.5V16.5C4.5 15.95 4.69583 15.4792 5.0875 15.0875C5.47917 14.6958 5.95 14.5 6.5 14.5H18.5C19.05 14.5 19.5208 14.6958 19.9125 15.0875C20.3042 15.4792 20.5 15.95 20.5 16.5V21.5H4.5ZM9.5 13.5C8.11667 13.5 6.9375 13.0125 5.9625 12.0375C4.9875 11.0625 4.5 9.88333 4.5 8.5C4.5 7.11667 4.9875 5.9375 5.9625 4.9625C6.9375 3.9875 8.11667 3.5 9.5 3.5H15.5C16.8833 3.5 18.0625 3.9875 19.0375 4.9625C20.0125 5.9375 20.5 7.11667 20.5 8.5C20.5 9.88333 20.0125 11.0625 19.0375 12.0375C18.0625 13.0125 16.8833 13.5 15.5 13.5H9.5ZM6.5 19.5H18.5V16.5H6.5V19.5ZM9.5 11.5H15.5C16.3333 11.5 17.0417 11.2083 17.625 10.625C18.2083 10.0417 18.5 9.33333 18.5 8.5C18.5 7.66667 18.2083 6.95833 17.625 6.375C17.0417 5.79167 16.3333 5.5 15.5 5.5H9.5C8.66667 5.5 7.95833 5.79167 7.375 6.375C6.79167 6.95833 6.5 7.66667 6.5 8.5C6.5 9.33333 6.79167 10.0417 7.375 10.625C7.95833 11.2083 8.66667 11.5 9.5 11.5ZM9.5 9.5C9.78333 9.5 10.0208 9.40417 10.2125 9.2125C10.4042 9.02083 10.5 8.78333 10.5 8.5C10.5 8.21667 10.4042 7.97917 10.2125 7.7875C10.0208 7.59583 9.78333 7.5 9.5 7.5C9.21667 7.5 8.97917 7.59583 8.7875 7.7875C8.59583 7.97917 8.5 8.21667 8.5 8.5C8.5 8.78333 8.59583 9.02083 8.7875 9.2125C8.97917 9.40417 9.21667 9.5 9.5 9.5ZM15.5 9.5C15.7833 9.5 16.0208 9.40417 16.2125 9.2125C16.4042 9.02083 16.5 8.78333 16.5 8.5C16.5 8.21667 16.4042 7.97917 16.2125 7.7875C16.0208 7.59583 15.7833 7.5 15.5 7.5C15.2167 7.5 14.9792 7.59583 14.7875 7.7875C14.5958 7.97917 14.5 8.21667 14.5 8.5C14.5 8.78333 14.5958 9.02083 14.7875 9.2125C14.9792 9.40417 15.2167 9.5 15.5 9.5Z"
        fill={fill}
      />
    </g>
  </svg>
);

export default VCIcon;
